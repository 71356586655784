import Button from '@mui/material/Button';
import EastIcon from '@mui/icons-material/East'
import './App.css';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/500.css'
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <CssBaseline />
      <Container component='main' sx={{ mt: 8, mb: 2 }} maxWidth='md'>
        <Grid container spacing={3}>
          <Grid>
            <Typography variant='h2' component='h1' sx={{ fontWeight: 700, fontSize: '4.75rem' }}>
              Versesmith
            </Typography>
          </Grid>
          <Grid>
            <Typography
              variant='subtitle1'
              sx={{
                fontWeight: 700,
                fontSize: '3.4rem',
                color: 'GrayText',
                lineHeight: '1  '
              }}
            >
              Seamlessly integrate the Bible into text, audio and vision.
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Button
              href='https://forms.gle/p8AX5zG3Z6b44yUR8'
              variant='contained'
              size='large'
              endIcon={<EastIcon />}
              sx={{
                backgroundColor: 'black',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'GrayText',
                },
              }}
            >
              Join waitlist for early access
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Box
        component='footer'
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
        }}
      >
        <Container maxWidth='md'>
          <Grid container spacing={2} direction='column'>
            <Grid container spacing={0} direction='row'>
              <Grid xs={12} md={4} lg={3}>
                General Contact
              </Grid>
              <Grid xs={12} md={8} lg={9}>
                <Link
                  href='mailto:info@versesmith.com'
                  color='inherit'
                  sx={{
                    textDecoration: 'none',
                    '&:hover': {
                      color: 'blue',
                    },
                  }}
                >
                  info@<span style={{ color: 'GrayText' }}>versesmith.com</span>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={0} direction='row'>
              <Typography
                variant='caption'
                sx={{
                  color: 'GrayText',
                }}
              >
                Copyright @ {new Date().getFullYear()} Versesmith Inc.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default App;
